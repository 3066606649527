import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { BREAKPOINTS, LAYOUT, ANIMATION } from '../../styles/cssVariables'
import Container from './container'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { isAbsoluteUrl } from '../../utils/utils'
import GreenDropdown from '../../images/icons/green_dropdown.svg'
import WhiteDropdown from '../../images/icons/white_dropdown.svg'

const MenuWrapper = styled.div`
  height: calc(100vh - 71px);
  width: 100vw;
  position: fixed;
  top: 71px;
  left: 0;

  background-color: var(--color-skobeloff);

  z-index: 1003;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.8s cubic-bezier(0.85, 0, 0.15, 1) 0.4s;

  ${(props) =>
    props.menuIsOpen &&
    `
    transform: scaleY(1);
    transition-delay: 0s;
  `}

`

const MenuContainer = styled(Container)`
  height: 100%;
  display: flex;

  padding-bottom: 38px;
`

const MenuPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  opacity: 0;
  transition: opacity 0.4s ease 0s;
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-top: 25%;
  }
  ${(props) =>
    props.menuIsOpen &&
    `
    opacity: 1;
    transition-delay: 0.8s;
  `}
`

const MenuPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  color: var(--color-white);
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 10px;

  }
`

const MenuItem = styled(AnchorLink)`
  font-size: 32px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
  

  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 19px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`
const MenuItemAbsolute = styled.a`
  font-size: 32px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 19px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

const MenuButton = styled(Link)`
  margin-top: auto;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #fff;
  margin-bottom: 40px;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;

  background-color: transparent;
  color: #fff;

  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-skobeloff) !important;  
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
    @media (max-width: ${BREAKPOINTS.med}px) {
      font-size: 28px;
    }
    @media (max-width: ${BREAKPOINTS.sml}px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  
`
const MenuButtonSecondary = styled.a`
  width: fit-content;
  position: relative;
 
  box-sizing: border-box;

  cursor: pointer;

  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
  padding: 1.25em 2.25em;
  color: var(--color-heading) !important; 
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;


  transition: color ${ANIMATION.speedMedium} ease,
    background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color: #fff !important; 
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
  @media (max-width: ${BREAKPOINTS.med}px) {
    font-size: 28px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
     font-size: 16px;
   }
  
`
const DropdownContainer = styled.div`
  overflow: hidden;
  text-align: center;
  > .openMobile {
    display: block;
  }
`
const DropdownContainerMission = styled.div`
overflow: hidden;
text-align: center;
> .openMobileMission {
  display: block;
}

`
const DropdownContainerTwo = styled.div`
overflow: hidden;
text-align: center;
> .openTwoMobile {
  display: block;
}

`
const DropdownContent = styled.div`
  display: none;
  background-color: white;
  margin-top: -20px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 1.75em 2.5em;
  margin-bottom: 20px;
  > a {
    text-decoration: none;
    text-align: center;
  
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    &:not(:last-child) {
      margin--top: 0;
    }
    padding: 1em 1.75em;

  }

`

const DropdownButton = styled.button`
  background-color: inherit;
  font-family: inherit;
  color: inherit;
  border: none;
  font-size: 32px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 19px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  cursor: pointer;

`

const DropdownLink = styled(Link)`
  background-color: inherit;
  font-family: inherit;
  color: inherit;
  border: none;
  font-size: 32px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 19px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  cursor: pointer;

`
const GreenCaret = styled(GreenDropdown)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-left: 0.2em;
  @media (max-width: ${BREAKPOINTS.sml}px) {
    width: 18px;
    height: 18px;
  }
`
const WhiteCaret= styled(WhiteDropdown)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-left: 0.2em;
  @media (max-width: ${BREAKPOINTS.sml}px) {
    width: 18px;
    height: 18px;
  }
`
const SubMenuItem = styled(AnchorLink)`

font-size: 32px;
  color: var(--color-skobeloff) !important;
  display: block;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  
  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 19px;
  }

`


/**
 * The mobile menu.
 * Fetches data from the CMS that populates the menu.
 */
const Menu = (props) => {
  const menuQuery = useStaticQuery(graphql`
    query menuQuery {
      contentfulSite {
        getListedLink
        headerCta
        headerSecondaryCtaText
        headerSecondaryCtaDestination
        navigationHeader1Dropdown {
          items {
            name
            link
          }
          title
        }
        navigationHeader2Dropdown {
          items {
            name
            link
          }
          title
        }
        navigationHeader3Dropdown {
          items {
            name
            link
          }
          title
        }
      }
    }
  `)
  const getListedLink = menuQuery.contentfulSite.getListedLink
  const headerCTA = menuQuery.contentfulSite.headerCta
  const headerCTASecondaryText = menuQuery.contentfulSite.headerSecondaryCtaText
  const headerCTASecondaryDestination = menuQuery.contentfulSite.headerSecondaryCtaDestination
  // const navHeaderFirstName = menuQuery.contentfulSite.navigationHeader1.name
  // const navHeaderFirstLink = menuQuery.contentfulSite.navigationHeader1.link
  const navHeaderFirstDropdown = menuQuery.contentfulSite.navigationHeader1Dropdown.items
  const navHeaderFirstDropdownTitle = menuQuery.contentfulSite.navigationHeader1Dropdown.title
  const navHeaderSecondDropdown = menuQuery.contentfulSite.navigationHeader2Dropdown.items
  const navHeaderSecondDropdownTitle = menuQuery.contentfulSite.navigationHeader2Dropdown.title
  // const navHeaderMissionDropdownTitle = menuQuery.contentfulSite.navigationHeader2Dropdown.title

  const navHeaderThirdDropdown = menuQuery.contentfulSite.navigationHeader3Dropdown.items
  const navHeaderThirdDropdownTitle = menuQuery.contentfulSite.navigationHeader3Dropdown.title
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  const [expandedMobile, setExpandedMobile] = useState(false);
  const [expandedTwoMobile, setExpandedTwoMobile] = useState(false);
  const [expandedMissionMobile, setExpandedMissionMobile] = useState(false);

  const handleExpandedMobile = () => {
    setExpandedMobile(!expandedMobile);
   if(expandedTwoMobile) {
     setExpandedTwoMobile(false)
   }
  }
  const handleExpandedTwoMobile = () => {
   setExpandedTwoMobile(!expandedTwoMobile);
   if(expandedMobile) {
     setExpandedMobile(false)
   }
 }

 const handleExpandedMissionMobile = () => {
  setExpandedMissionMobile(!expandedMissionMobile);
  if(expandedMissionMobile) {
    setExpandedMissionMobile(false)
  }
}



 let buttonClassesMobile=['dropdown-menu-mobile'];

  if(expandedMobile){
    buttonClassesMobile.push('openMobile');
   
  }
  if(expandedMissionMobile){
    buttonClassesMobile.push('openMobileMission');
   
  }


  if(expandedTwoMobile){
    buttonClassesMobile.push('openTwoMobile');
  }
 

  return (
    <MenuWrapper className={props.className} menuIsOpen={props.menuIsOpen} id="menuWrapper">
      <MenuContainer width={12}>
        <MenuPanel menuIsOpen={props.menuIsOpen}>
          <MenuPanelContent as="nav" id="menuPanelContent">
            
               
          {/* <MenuItem to={navHeaderFirstLink} title={navHeaderFirstName} className="stripped" stripHash>{navHeaderFirstName}</MenuItem> */}
          <DropdownContainerMission>
              <DropdownButton aria-haspopup="menu" aria-expanded={expandedMissionMobile} onClick={handleExpandedMissionMobile}>{navHeaderFirstDropdownTitle}  {props.logoColorWhite ? (
                <WhiteCaret /> 
              ) : (
                <GreenCaret />
              )} </DropdownButton>
      
            
              <DropdownContent id="dropdownContentMissionMobile" className={buttonClassesMobile.join(" ")} onMouseLeave={handleExpandedMissionMobile}>
              {navHeaderFirstDropdown.map((item) => (
              <SubMenuItem key={item.name} to={item.link} title={item.name} className="stripped subMenuItemMobile"
              stripHash>
                {item.name}
              </SubMenuItem>
            ))}
             
              </DropdownContent>
            </DropdownContainerMission>
          <DropdownContainer>
              <DropdownButton aria-haspopup="menu" aria-expanded={expandedMobile} onClick={handleExpandedMobile}>{navHeaderSecondDropdownTitle}  {props.logoColorWhite ? (
                <WhiteCaret /> 
              ) : (
                <GreenCaret />
              )} </DropdownButton>
      
            
              <DropdownContent id="dropdownContentMobile" className={buttonClassesMobile.join(" ")} onMouseLeave={handleExpandedMobile}>
              {navHeaderSecondDropdown.map((item) => (
              <SubMenuItem key={item.name} to={item.link} title={item.name} className="stripped subMenuItemMobile"
              stripHash>
                {item.name}
              </SubMenuItem>
            ))}
             
              </DropdownContent>
            </DropdownContainer>
            <DropdownContainerTwo>
              <DropdownButton aria-haspopup="menu" aria-expanded={expandedTwoMobile} onClick={handleExpandedTwoMobile}>{navHeaderThirdDropdownTitle} {props.logoColorWhite ? (
                <WhiteCaret /> 
              ) : (
                <GreenCaret />
              )}</DropdownButton>
      
              <DropdownContent id="dropdownContentMobileTwo" className={buttonClassesMobile.join(" ")} onMouseLeave={handleExpandedTwoMobile}>
              {navHeaderThirdDropdown.map((item) => (
              <SubMenuItem key={item.name} to={item.link} title={item.name} className="stripped subMenuItemMobileTwo"
              stripHash onAnchorLinkClick={props.closeMenu}>
                {item.name}
              </SubMenuItem>
            ))}
             
              </DropdownContent>
            </DropdownContainerTwo>
        
             
        
          </MenuPanelContent>

          <MenuPanelContent>
          {pathname !=="/join-the-community" &&  
            <MenuButton to={getListedLink} id="menuButton">
                      
            {headerCTA}

            </MenuButton>
          
          }
              <MenuButtonSecondary href={headerCTASecondaryDestination} rel="noreferrer" target="_blank" id="menuButtonSecondary">
          
                {headerCTASecondaryText}
     
            </MenuButtonSecondary>
          </MenuPanelContent>
        </MenuPanel>
      </MenuContainer>
    </MenuWrapper>
  )
}

Menu.propTypes = {
  /** styled-components className prop */
  className: PropTypes.string,
  /** Menu open state, passed from & managed by the Header component */
  menuIsOpen: PropTypes.bool.isRequired,
}

Menu.defaultProps = {
  isOpen: false,
}

export default Menu
