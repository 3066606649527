import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'
// import { LAYOUT, BREAKPOINTS } from '../../styles/cssVariables'
import { LAYOUT, ANIMATION, BREAKPOINTS } from '../../styles/cssVariables'
import { isAbsoluteUrl } from '../../utils/utils'
import SvgLogomark from '../../images/mindful_commerce_lighter_green_logo_footer.svg'
import SocialMediaIcons from '../ui-kit/socialMediaIcons'
import IconLinkArrowSvg from '../../images/icons/link-arrow.svg'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ContainerWrapper = styled.div`
  padding: 0 ${LAYOUT.gutterPage}px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
  `
const Prefooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  border-radius: 4px;
  background-color: var(--color-skobeloff);
  color: #fff;

  transform: translateY(-90px);
  overflow: hidden;

 

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 40px;
    margin-bottom: calc(50px - 90px);

   
  }
`

const PrefooterHeading = styled.h3`
    text-align: center;
    margin-bottom: 35px;
    font-size: 35px;
    color: var(--color-white);

    @media (max-width: ${BREAKPOINTS.tbl}px) {
      margin-bottom: 35px;

      font-size: 26px;
   
    }
  
`

const PrefooterContent = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 100%;
  }
`

const PrefooterContentText = styled.p`
  margin-bottom: 30px;
  font-size: 17px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: var(--color-white-85);
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 22px;
    font-size: 15px;
  }
`

const FooterButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-white);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white);
  color: var(--color-skobeloff) !important;

  transition: color ${ANIMATION.speedMedium} ease,
    background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-skobeloff);
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`

const FooterButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-white);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white);
  color: var(--color-skobeloff) !important;


  transition: color ${ANIMATION.speedMedium} ease,
    background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-skobeloff);
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

const MainFooter = styled.div`
display: flex;
flex-direction: row;
padding: 0 ${LAYOUT.gutterPage}px;
box-sizing: border-box;
width: 100%;
max-width: 1840px;
margin-left: auto;
margin-right: auto;
margin-bottom: 115px;
@media (max-width: ${BREAKPOINTS.medUp}px) {
  padding: 0 ${LAYOUT.gutterPage / 2}px;
}
@media (max-width: ${BREAKPOINTS.tbl}px) {
  margin-bottom: 18px;

 
    flex-direction: column;
  
}
@media (max-width: ${BREAKPOINTS.sml}px) {
  padding: 0 ${LAYOUT.gutterPageMobile}px;
}

 
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 2;
  flex: 3;
  font-size: 13px;

  overflow-x: hidden;



  @media (max-width: ${BREAKPOINTS.med}px) {

    width: 100%;
   

  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {

    align-items: center;
    margin-bottom: 50px;
   

  }
`
const FooterColContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex: 5;
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    flex-direction: column;
  }
`

const ColRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  margin-right: 13%;
  overflow-x: hidden;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-right: 9%;
  }

  @media (max-width: 875px) {
    margin-right: 7%;
  }

  @media (max-width: ${BREAKPOINTS.tbl}px) {
    overflow-x: unset;

    margin-right: 0;
    margin-bottom: 50px;
    align-items: center;


  }
  
`
const LogomarkStyled = styled.img`
  margin-bottom: 32px;
  width: 100px;
  @media (max-width: ${BREAKPOINTS.sml}px) {
    width: 90px;
    }
  // @media (max-width: ${BREAKPOINTS.sml}px) {
  //   display: none;
  // }
`

// const LogomarkStyledMobile = styled(SvgLogomark)`
//   margin-bottom: 32px;
//   @media (min-width: ${BREAKPOINTS.smlUp}px) {
//     display: none;
//   }
// `

const SocialMediaIconsStyled = styled(SocialMediaIcons)`
  margin-bottom: 10px;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 18px;


  }


`

const TextLink = styled.a`
  text-decoration: underline;
  font-size: 13px;
  &:hover {
    color: var(--color-heading);
    text-decoration: none;
  }
`

const FooterLink = styled(AnchorLink)`
  font-size: 14px;
  transition: text-decoration ${ANIMATION.speedMedium} ease;

  &:not(:last-child) {
    margin-bottom: 26px;
  }

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`

const FooterLinkExternal = styled.a`
  font-size: 14px;
  transition: color ${ANIMATION.speedMedium} ease;

  &:not(:last-child) {
    margin-bottom: 26px;
  }

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`

const FooterCopyrightContainer = styled.div`
  margin-top: 44px;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 24px;
  }

`

const FooterCopyright = styled.p`
  font-size: 13px;

  // a {
  //   font-size: 13px;
  //   text-decoration: underline;
  // }
`

const FooterEmail = styled.a`
  font-size: 13px;


  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  
`

const NavFooterTitle = styled.h4`
  font-size: 16px;
`

/**
 * The pre-footer & footer for the site.
 * Fetches data from the CMS that populates the content areas.
 * Adapts content based on the URL - Join as a Brand & Join as an Expert have unique content.
 */
const Footer = (props) => {
  const footerQuery = useStaticQuery(graphql`
    query footerQuery {

      
      contentfulSite {
        navigationFooter1 {
          title
          items {
            name
            link
          }
        }
        navigationFooter2 {
          title
          items {
            name
            link
          }
        }
        navigationFooter3 {
          title
          items {
            name
            link
          }
        }
        prefooterSitewide {
          body {
            body
          }
          ctaText
          ctaDestination
          title
        }
      }
      contentfulPageLandingCommunity {
        prefooterJoinPageOnly {
          body {
            body
          }
          ctaText
          ctaDestination
          title
        }
      }
     
    }
  `)

  const navigationFooter1 = footerQuery.contentfulSite.navigationFooter1
  const navigationFooter2 = footerQuery.contentfulSite.navigationFooter2
  const navigationFooter3 = footerQuery.contentfulSite.navigationFooter3


  const prefooterTitle = props.location.pathname.includes('join-the-community')
  ? footerQuery.contentfulPageLandingCommunity.prefooterJoinPageOnly.title : footerQuery.contentfulSite.prefooterSitewide.title
  const prefooterBody = props.location.pathname.includes('join-the-community') ? footerQuery.contentfulPageLandingCommunity.prefooterJoinPageOnly.body.body : footerQuery.contentfulSite.prefooterSitewide.body.body
  const prefooterCTA = props.location.pathname.includes('join-the-community') ? footerQuery.contentfulPageLandingCommunity.prefooterJoinPageOnly.ctaText : footerQuery.contentfulSite.prefooterSitewide.ctaText
  const prefooterCTADestination = props.location.pathname.includes('join-the-community') ? footerQuery.contentfulPageLandingCommunity.prefooterJoinPageOnly.ctaDestination : footerQuery.contentfulSite.prefooterSitewide.ctaDestination


   

  return (
    <footer>
      {props.preFooter && ( 
         <ContainerWrapper width={12}>
          <Prefooter>
            <PrefooterHeading>
              {prefooterTitle}
            </PrefooterHeading>
            <PrefooterContent>
                <PrefooterContentText>{prefooterBody}</PrefooterContentText>
          
                    {isAbsoluteUrl(prefooterCTADestination) ? (
                      <FooterButton
                        href={prefooterCTADestination}
                        target="__blank"
                        rel="nofollow"
                      >
                        {prefooterCTA}
                        <Arrow />
                      </FooterButton>
                    ) : (
                      <FooterButtonGatsbyLink to={prefooterCTADestination}>
                        {prefooterCTA}
                        <Arrow />
                      </FooterButtonGatsbyLink>
                    )}   
            </PrefooterContent>
          </Prefooter>
        </ContainerWrapper>
      )} 

      <MainFooter align="left" flex={true} width={12}>
        <Col>
          <LogomarkStyled src={SvgLogomark} alt="MindfulCommerce home page" />
          <SocialMediaIconsStyled />
          <FooterEmail href="mailto:info@mindfulcommerce.io">
            info@mindfulcommerce.io
          </FooterEmail>

          <FooterCopyrightContainer>
            <FooterCopyright>
              &copy;{new Date().getFullYear()} MindfulCommerce
            </FooterCopyright>
            <FooterCopyright>
              Sponsored by{' '}
              <TextLink
                href="https://www.kollectify.com"
                rel="noreferrer"
                target="_blank"
              >
                Kollectify
              </TextLink>
            </FooterCopyright>
          </FooterCopyrightContainer>
        </Col>
        <FooterColContainer>        
          <ColRight>
            <NavFooterTitle>{navigationFooter1.title}</NavFooterTitle>
            {navigationFooter1.items.map((item) => {
              return isAbsoluteUrl(item.link) ? (
                <FooterLinkExternal
                  href={item.link}
                  key={item.name}
                  rel="noreferrer"
                  target="_blank"
                >
                  {item.name}
                </FooterLinkExternal>
              ) : (
                <FooterLink key={item.name} to={item.link} title={item.name} className="stripped"
                stripHash>
                  {item.name}
                </FooterLink>
              )
            })}
          </ColRight>

          <ColRight>
            <NavFooterTitle>{navigationFooter2.title}</NavFooterTitle>
            {navigationFooter2.items.map((item) => {
              return isAbsoluteUrl(item.link) ? (
                <FooterLinkExternal
                  href={item.link}
                  key={item.name}
                  rel="noreferrer"
                  target="_blank"
                >
                  {item.name}
                </FooterLinkExternal>
              ) : (
                <FooterLink key={item.name} to={item.link} title={item.name} className="stripped"
                stripHash>
                  {item.name}
                </FooterLink>
              )
            })}
          </ColRight>

          <ColRight>
            <NavFooterTitle>{navigationFooter3.title}</NavFooterTitle>
            {navigationFooter3.items.map((item) => {
              return isAbsoluteUrl(item.link) ? (
                <FooterLinkExternal
                  href={item.link}
                  key={item.name}
                  rel="noreferrer"
                  target="_blank"
                >
                  {item.name}
                </FooterLinkExternal>
              ) : (
                <FooterLink key={item.name} to={item.link} title={item.name} className="stripped"
                stripHash>
                  {item.name}
                </FooterLink>
              )
            })}
          </ColRight>
        </FooterColContainer>      
      </MainFooter>
    </footer>
  )
}

Footer.propTypes = {
  /** Should this instance of footer show the pre-footer? */
  preFooter: PropTypes.bool,
}

Footer.defaultProps = {
  preFooter: true,
}

export default Footer
