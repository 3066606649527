import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import SharingImg from '../../images/social_sharing_logo.png'
/**
 * A component injected in each page's layout that sets SEO information in the document head, via React Helmet.
 * Fetches data from the CMS that populates the SEO information.
 * Specifies default information, if the CMS has been left empty or the data fetching fails.
 */
const SEO = (props) => {
  const seoQuery = useStaticQuery(graphql`
    query seoQuery {
      contentfulSite {
        siteTitle
        seoDescription
      }
      allFile(
        filter: {
          extension: { regex: "/(jpeg|jpg|gif|png)/" }
          name: { eq: "social_sharing_logo" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  `)
  const siteTitle = seoQuery?.contentfulSite?.siteTitle
  // const socialSharingImage = seoQuery?.allFile?.edges[0]?.node?.childImageSharp
  const pageDescription = props?.seoDescription ? props.seoDescription : seoQuery?.contentfulSite?.seoDescription
  // let ogImage = props?.ogImage ? props.ogImage : socialSharingImage.original.src
  // if (ogImage.indexOf("http://") != 0 || ogImage.indexOf("https://") != 0) {
  //   ogImage = `https:${ogImage}`
  // }


  return (
    <Helmet
      title={props.pageTitle ? `${props.pageTitle} · ${siteTitle}` : siteTitle}
    >
      <meta
        name="description"
        content={pageDescription}
      />
     
      <meta
        property="og:title"
        content={
          props.pageTitle ? `${props.pageTitle} · ${siteTitle}` : siteTitle
        }
      />
      <meta
        property="og:description"
        content={pageDescription}
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={SharingImg} />
      <html lang="en-GB" />
    </Helmet>
  )
}

export default SEO
