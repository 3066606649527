import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { ANIMATION } from '../../styles/cssVariables'

import IconInstagramSVG from '../../images/icons/instagram.svg'
import IconFacebookSVG from '../../images/icons/facebook.svg'
import IconLinkedInSVG from '../../images/icons/linkedIn.svg'

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;

  > * {
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const IconLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;

  line-height: 0;
  font-size: 0;
  color: transparent;
  > svg path:first-child:not(#Path_44168) {
    fill: var(--color-skobeloff);
  }
  > svg path {
    transition: fill ${ANIMATION.speedMedium} ease;
  }
  &:hover > svg path:first-child:not(#Path_44168) {
    fill: var(--color-skobeloff);
  }
`

/**
 * Renders clickable social media icon SVGs as per brand guidelines.
 * Fetches data from the Site data type in the CMS to populate social media links.
 */
const SocialMediaIcons = (props) => {
  const siteData = useStaticQuery(graphql`
    query socialQuery {
      contentfulSite {
        instagram
        facebook
        linkedIn
      }
    }
  `).contentfulSite

  return (
    <IconsContainer className={props.className}>
      <IconLink href={siteData.instagram} target="_blank" rel="noreferrer">
        MindfulCommerce Instagram link
        <IconInstagramSVG alt="MindfulCommerce Instagram link" />
      </IconLink>
      <IconLink href={siteData.facebook} target="_blank" rel="noreferrer">
        MindfulCommerce Facebook link
        <IconFacebookSVG alt="MindfulCommerce Facebook link" />
      </IconLink>
      <IconLink href={siteData.linkedIn} target="_blank" rel="noreferrer">
        MindfulCommerce LinkedIn link
        <IconLinkedInSVG alt="MindfulCommerce LinkedIn link" />
      </IconLink>
    </IconsContainer>
  )
}

export default SocialMediaIcons
