import { css } from 'styled-components'

/** Used to calculate maximum content width for the Container component */
const columnCount = 12

/** Site-wide constants governing HTML element layout */
export const LAYOUT = {
  pageWidth: 1440,
  gutterPage: 150,
  gutterPageMobile: 22,
  columnWidth: (1 / columnCount) * 100,
  headerHeightMobile: 66,
}

/** Site-wide constants governing animation speed & style */
export const ANIMATION = {
  speedFast: '100ms',
  speedMedium: '200ms',
  speedSlow: '400ms',
  functionSlideIn: 'cubic-bezier(.33,0,.2,1)',
}

/** Site-wide constants governing responsive breakpoints */
export const BREAKPOINTS = {
  sml: '500',
  smlUp: '501',
  tbl: '809',
  tblUp: '810',
  med: '1079',
  medUp: '1080',
  lrg: '1366',
  lrgUp: '1367',
}

/**
 * Site-wide styles governing colours & typography.
 * Used by styled-components createGlobalStyle()
 * */
export const GLOBALSTYLES = css`
  :root {
    --color-violet: #F9A52B;
    --color-skobeloff: #006163;
    --color-blue: #13B7B3;
    --color-mint: #A1E5CF;
    --color-heading: #006163;
    --color-body: #227476;
    --color-white: #FFFCFA;
    --color-white-85: #FBF8F6;
  }

  html,
  body,
  input {
    font-family: 'Poppins', sans-serif;
  }



  body {
    position: relative;
    background-color: #fff;
    color: var(--color-body);
  }

  b {
    font-weight: 700;
  }

  h1,
  .h1 {
    
    font-size: 55px;

    line-height: 1.15;
    color: var(--color-heading);

    @media (max-width: ${BREAKPOINTS.lrg}px) {
      font-size: 48px;
    }

    @media (max-width: ${BREAKPOINTS.medUp}px) {
      font-size: 34px;
    }
  }

  h2,
  .h2 {
    
    font-size: 36px;
  
    line-height: 1.25;
    color: var(--color-heading);

    @media (max-width: ${BREAKPOINTS.lrg}px) {
      font-size: 31px;
    }

    @media (max-width: ${BREAKPOINTS.medUp}px) {
      font-size: 26px;
      line-height: 1.3;
    }
  }

  h3,
  .h3 {
    font-size: 20px;

    line-height: 1.4;
    color: var(--color-heading);

    @media (max-width: ${BREAKPOINTS.medUp}px) {
      font-size: 16px;
      line-height: 1.65;
    }

  }

  h4,
  .h4 {
    margin-bottom: 32px;
    font-size: 15px;
  
    line-height: 1.5;
    color: var(--color-heading);

    @media (max-width: ${BREAKPOINTS.lrg}px) {
      margin-bottom: 18px;
    }
  }

  h5,
  .h5 {
    margin-bottom: 20px;
    font-size: 17px;
   
    line-height: 1.85;
    color: var(--color-heading);

    @media (max-width: ${BREAKPOINTS.lrg}px) {
      font-size: 16px;
      line-height: 2;
    }
  }

  h6,
  .h6 {
    margin-bottom: 32px;
    font-size: 15px;

    line-height: 1.5;
    color: var(--color-heading);

    @media (max-width: ${BREAKPOINTS.lrg}px) {
      margin-bottom: 18px;
    }
  }

  p,
  ol,
  ul,
  a {
    font-size: 16px;
    line-height: 1.85;

    @media (max-width: ${BREAKPOINTS.sml}px) {
      font-size: 13px;
      line-height: 1.8;
    }
  }

  ol,
  ul {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  ul {
    list-style-type: disc;
  }

  a {
    position: relative;
    line-height: 1.5;
    color: inherit;
    text-decoration: none;
    &:visited {
      color: inherit;
    }
  }

  blockquote {
    color: var(--color-body);
    font-size: 32px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.3;
    quotes: '“' '”' '‘' '’';

    @media (max-width: ${BREAKPOINTS.sml}px) {
      font-size: 22px;
    }
  }

  blockquote::before {
    content: open-quote;
  }

  blockquote::after {
    content: close-quote;
  }

  .ReactModal__Html--open,
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .modal--dialog-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(58, 71, 82, 0.3);

    @media (max-width: ${BREAKPOINTS.med}px) {
      padding: 0 ${LAYOUT.gutterPage}px;
    }
  }
  .modal--dialog-content {
    width: 50%;
    height: 43%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    padding: 55px 75px;
    box-sizing: border-box;
    margin: auto;

    outline: none;
    background-color: var(--color-white);
    background-repeat: no-repeat;
    background-size: cover;

    overflow: visible;

    @media (max-width: ${BREAKPOINTS.med}px) {
      width: 100%;
      height: auto;

      padding: ${LAYOUT.gutterPage}px;
    }
  }

  .modal--lightbox-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .modal--lightbox-content {
    width: 100vw;
    height: 100vh;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    padding: ${LAYOUT.gutterGlobal * 5}px ${LAYOUT.gutterGlobal * 7}px;
    border: none;
    border-radius: 0;

    outline: none;
    background-color: #f3f5f7;

    @media (max-width: ${BREAKPOINTS.med}px) {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: ${LAYOUT.gutterPage}px;
      box-sizing: border-box;
    }
  }

  
`
