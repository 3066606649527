import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'
import styled from 'styled-components'

import { GLOBALSTYLES } from '../../styles/cssVariables'
import Header from './header'
import Footer from './footer'
import SharingImg from '../../images/social_sharing_logo.png'

import { Helmet } from 'react-helmet'
const GlobalStyle = createGlobalStyle`
  ${GLOBALSTYLES}
`

const MainContent = styled.main`
  position: relative;
  overflow: hidden;

    margin-top: -3px;
  
`

/**
 * General page layout - header, content, footer.
 * Also includes global style reset & global CSS styles
 */
class Template extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'auto'
  }

  render() {
    /** Location data provided by Reach Router via Gatsby: https://www.gatsbyjs.com/docs/location-data-from-props/ */
    const { location, children } = this.props

    return (
      <>
        <Helmet>
          <meta property="og:image" content={SharingImg} />
          <script async defer src="https://scripts.withcabin.com/hello.js"></script>
        </Helmet>
        <Reset />
        <GlobalStyle />

        <Header />

        <MainContent>{children}</MainContent>
        <Footer location={location} />


      </>
    )
  }
}

export default Template
