import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { LAYOUT, BREAKPOINTS } from '../../styles/cssVariables'

const ContainerWrapper = styled.div`
  padding: 0 ${LAYOUT.gutterPage}px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media (max-width: 960px) {
    padding-left: 175px;
    padding-right: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tblUp}px) {
    padding-left: 125px;
    padding-right: 125px;
  }
  @media (max-width: 675px) {
    padding-left: 75px;
    padding-right: 75px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-left: ${LAYOUT.gutterPageMobile}px;
    padding-right: ${LAYOUT.gutterPageMobile}px;
  }
`

const ContainerDiv = styled.div`
  // position: relative;
  display: ${(props) => (props.flex ? 'flex' : 'inherit')};
  ${(props) => props.flex && 'flex-direction: row'};
  ${(props) => props.flex && props.align === 'center' && 'align-items: center'};
  width: ${(props) => LAYOUT.columnWidth * props.width}%;
  margin-left: ${(props) => (props.align === 'left' ? 0 : 'auto')};
  margin-right: ${(props) => (props.align === 'right' ? 0 : 'auto')};
  // @media (max-width: ${BREAKPOINTS.med}px) {
  //  width: 100%;
  //   padding: 0 200px;
  //  margin-left: auto;
  //  margin-right: auto;
  // }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    width: 100%;
  }
`

/**
 * A container that conforms to global style rules about the content width & rules from the design files e.g. column width.
 */
const Container = (props) => {
  const { children } = props

  return (
    <ContainerWrapper className={props.className} id={props.id}>
      <ContainerDiv align={props.align} flex={props.flex} width={props.width}>
        {children}
      </ContainerDiv>
    </ContainerWrapper>
  )
}

Container.propTypes = {
  /** Specifies how this container's content block should be aligned on the page, if this container is less than full content width */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /** styled-components className prop */
  className: PropTypes.string,
  /** Whether this container should be a flex parent */
  flex: PropTypes.bool,
  /** How many columns this container should occupy. Max value: columnCount in cssVariables.js */
  width: PropTypes.number.isRequired,
}

Container.defaultProps = {
  align: 'center',
  flex: false,
}

export default Container
