import { Link, useStaticQuery, graphql } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { isAbsoluteUrl } from '../../utils/utils'
import { LAYOUT, BREAKPOINTS, ANIMATION } from '../../styles/cssVariables'
import IconMenuSvg from '../../images/icons/icon-menu.svg'
import IconCloseSvg from '../../images/icons/icon-close.svg'
import { GatsbyImage } from 'gatsby-plugin-image'
import Menu from './menu'
import GreenDropdown from '../../images/icons/green_dropdown.svg'
import WhiteDropdown from '../../images/icons/white_dropdown.svg'


const HeaderWrapper = styled.header`
  width: 100%;
  background-color: var(--color-skobeloff);
  transition: all .7s ease-in;
  box-sizing: border-box;
  z-index: 999;
  &.scrolled {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      a, button {
        color: var(--color-heading) !important;
      }
      #dropdownContentMobile {
        background-color: var(--color-skobeloff);
        border: 1px solid var(--color-skobeloff);
      }
 
      #dropdownContentMobileTwo {
       background-color: var(--color-skobeloff);
       border: 1px solid var(--color-skobeloff);
     }
     #dropdownContentMissionMobile {
      background-color: var(--color-skobeloff);
      border: 1px solid var(--color-skobeloff);
    }
      .subMenuItemMobile {
        color: var(--color-white) !important;
 
      }
      .subMenuItemMobileTwo {
        color: var(--color-white) !important;
 
     }
      #primaryCTADesktop {
        border: 1px solid var(--color-heading);
        color: var(--color-heading) !important;
        &:hover {
          background-color: var(--color-heading);
          color: var(--color-white) !important;
        }
      }
      #secondaryCTADesktop {
        border: 1px solid var(--color-heading);
        color: #fff !important;
        background-color: var(--color-heading);
        &:hover {
          background-color: #fff;
          color: var(--color-heading) !important;
        }
      }
      #secondaryCTADesktopGatsbyLink {
        border: 1px solid var(--color-heading);
        color: #fff !important;
        background-color: var(--color-heading);
        &:hover {
          background-color: #fff;
          color: var(--color-heading) !important;
        }
      }
      #openHamburger path {
        fill: var(--color-heading) !important;
      }
      #closeHamburger path {
        fill: var(--color-heading) !important;
    
      }
      #menuWrapper {
        background-color: #fff;
      }
      #menuPanelContent a {
        color: var(--color-heading);
      }
      #menuButton {
        background-color: transparent;
            border-color: var(--color-heading);
            color: var(--color-heading) !important;
              &:hover {
                background-color: var(--color-heading);
                color: var(--color-white) !important;
              }
      }
      #menuButtonSecondary {
        border: 1px solid var(--color-heading);
            color: #fff !important;
            background-color: var(--color-heading);
            &:hover {
              background-color: #fff;
              color: var(--color-heading) !important;
            }
        }
     }

     
   
`

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1840px;
  display: flex;
  flex-direction: row;
  align-items: center;
 justify-content: space-between;
  box-sizing: border-box;
  padding: 20px ${LAYOUT.gutterPage}px 20px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 20px ${LAYOUT.gutterPage / 2}px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 17px ${LAYOUT.gutterPageMobile}px;
  }
`

const LogoContainer = styled(Link)`
  width: 180px;
   
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 150px;
  }
 
`

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  overflow: hidden;

  color: var(--color-white);
  @media (max-width: 1374px) {
    display: none;
  }
  > a:last-child {
    margin-left: 60px;
  }
  @media (max-width: 1258px) {
    > a:last-child {
      margin-left: 30px;
    }
  }
`

const MenuItem = styled(Link)`
  margin: 0 30px;
  margin-top: 0.4em;
  font-size: 15px;
  transition: color 275ms ease;

 
 

  &:after {
    display: block;
    content: "";
    border-bottom: 2px solid var(--color-violet);
    transform: scaleX(0);
    transition: transform 275ms ease-in-out;
    transform-origin: 100% 50%;
    margin-top: 0.3em;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }


  @media (max-width: 1258px) {
    margin: 0 15px;
    &:last-child {
      margin-right: 0;
    }
  }
`
const SubMenuItem = styled(AnchorLink)`

  font-size: 15px;
  color: var(--color-skobeloff) !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  // &:after {
  //   display: block;
  //   content: "";
  //   width: 0;
  //   height: 2px; 
  //   background: var(--color-violet);
  //   transition: width .3s;
  //   border-bottom: 2px solid var(--color-violet);
  //   transform: scaleX(0);
  //   transition: border-bottom 275ms ease-in-out;
  //   transform-origin: 100% 50%;
  //   margin-top: 0.3em;
  // }
  // &:hover:after {
  //   border-bottom: 2px solid var(--color-violet);

  //   transform: scaleX(1);
  //   transform-origin: 0% 50%;
  // }


`
// const SubMenuSpan = styled.span `
//   position: relative; 
//   &:after {
//     position: absolute;
//     bottom: 0px;
//     content: "";
//     width: 0;
//     height: 2px; 
//     background: var(--color-violet);
//     left: 0;
//     transition: .3s all;
//   }
//   &:after:hover {
//     width: 100%;
//   }
// `
const CTAButton = styled(Link)`
  width: fit-content;
  position: relative;
 
  box-sizing: border-box;

  cursor: pointer;

  font-size: 15px;
 
  padding: 0.75em 1.75em;  
  border: 1px solid var(--color-white);
  border-radius: 4px;
  margin-right: 1.2em;
  color: var(--color-white) !important;
  background-color: transparent;

  transition: color ${ANIMATION.speedMedium} ease,
    background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: var(--color-white);
    color: var(--color-skobeloff) !important;
  }

`



const SecondaryCTAButton = styled.a`
  width: fit-content;
  position: relative;

  box-sizing: border-box;

  cursor: pointer;

  font-size: 15px;
  padding: 0.75em 1.75em;  
  color: var(--color-heading) !important; 
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;


  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color: #fff !important; 
  }
`

const ButtonContainerDesktop = styled.div`
  @media (max-width: 1374px) {
    display: none;
  }

`
const HamburgerMenuContainer = styled.div`
  height: 24px;
  width: 24px;
  position: relative;

  z-index: 1010;
  @media (min-width: 1375px) {
    display: none;
  }
`

const sharedStylesHamburgerButton = css`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;

  transition: opacity ${ANIMATION.speedMedium} ease;

  &:first-child {
    ${(props) =>
      !props.$menuIsOpen &&
      `
      opacity: 1;
      pointer-events: auto;
    `}
  }
  &:last-child {
    ${(props) =>
      props.$menuIsOpen &&
      `
      opacity: 1;
      pointer-events: auto;
    `}
  }
`

const DropdownContainer = styled.div`
  float: left;
  overflow: hidden;
  margin-left: 30px;

  > .open {
    display: block;
  }
  &:last-child {
    margin-left: 60px;
  }
`
const DropdownContainerMission = styled.div`
  float: left;
  overflow: hidden;
  margin-left: 30px;
  margin-right: 30px;
  > .openMission {
    display: block;
  }
  &:last-child {
    margin-left: 60px;
  }
`

const DropdownContainerTwo = styled.div`
float: left;
overflow: hidden;
margin-left: 30px;

> .openTwo {
  display: block;
}
&:last-child {
  margin-left: 60px;
}
`

const DropdownContent = styled.div`
  display: none;
  position: absolute;   
  z-index: 3000;
  background-color: #fff;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  border-radius: 4px;
  > a {
    float: none;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

`

const DropdownButton = styled.button`
  background-color: inherit;
  font-family: inherit;
  color: inherit;
  border: none;
  font-size: 15px;
  padding-bottom: 5px;
  cursor: pointer;
  margin-top: 0.2em;
`

const OpenHamburgerButton = styled(IconMenuSvg)`
  ${sharedStylesHamburgerButton};
`

const CloseHamburgerButton = styled(IconCloseSvg)`
  ${sharedStylesHamburgerButton};
`
const GreenCaret = styled(GreenDropdown)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 32px;
`
const WhiteCaret= styled(WhiteDropdown)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 35px;
`

// const CaretContainer = styled.div`
//   margin-top: 0.5em;
//   display: inline-block;
// `
/**
 * The header for the site.
 * Fetches data from the CMS that populates the header navigation/hamburger menu.
 * If it is rendered on a mobile device, it will show the hamburger menu instead of the header navigation.
 */
const Header = () => {
  const headerQuery = useStaticQuery(graphql`
    query headerQuery {
      contentfulSite {
        logo {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        greenLogo {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        headerSecondaryCtaText
        headerSecondaryCtaDestination
        headerCta
        navigationHeader1Dropdown {
          items {
            name
            link
          }
          title
        }
        navigationHeader2Dropdown {
          items {
            name
            link
          }
          title
        }
        navigationHeader3Dropdown {
          items {
            name
            link
          }
          title
        }
        getListedLink
      }
    }
  `)
  // const navigationItems = headerQuery.contentfulSite.navigationMain
  const getListedLink = headerQuery.contentfulSite.getListedLink
  const headerCTA = headerQuery.contentfulSite.headerCta
  const headerCTASecondaryText = headerQuery.contentfulSite.headerSecondaryCtaText
  const headerCTASecondaryDestination = headerQuery.contentfulSite.headerSecondaryCtaDestination


  /** Header manages the Menu's open state, since the element that updates that state (the hamburger icon) lives in the Header */
  const [menuIsOpen, toggleMenu] = useState(false)
  const logo = headerQuery.contentfulSite.logo
  const greenLogo = headerQuery.contentfulSite.greenLogo
  // const navHeaderFirstName = headerQuery.contentfulSite.navigationHeader1.name
  // const navHeaderFirstLink = headerQuery.contentfulSite.navigationHeader1.link
  const navHeaderFirstDropdown = headerQuery.contentfulSite.navigationHeader1Dropdown.items
  const navHeaderFirstDropdownTitle = headerQuery.contentfulSite.navigationHeader1Dropdown.title

  const navHeaderSecondDropdown = headerQuery.contentfulSite.navigationHeader2Dropdown.items
  const navHeaderSecondDropdownTitle = headerQuery.contentfulSite.navigationHeader2Dropdown.title
  const navHeaderThirdDropdown = headerQuery.contentfulSite.navigationHeader3Dropdown.items
  const navHeaderThirdDropdownTitle = headerQuery.contentfulSite.navigationHeader3Dropdown.title
  const [scrolled,setScrolled]= useState(false);
  const [logoColorWhite, setLogoColorGreen] = useState(true)
  const [expanded, setExpanded] = useState(false);
  const [expandedTwo, setExpandedTwo] = useState(false);
  const [expandedMission, setExpandedMission] = useState(false);

  const handleMenuAction = () => {
    toggleMenu(!menuIsOpen)
    document.body.style.overflow === 'hidden'
      ? (document.body.style.overflow = 'auto')
      : (document.body.style.overflow = 'hidden')
  }

  const closeMenu = () => {
    toggleMenu(!menuIsOpen)
    document.body.style.overflow === 'hidden'
      ? (document.body.style.overflow = 'auto')
      : (document.body.style.overflow = 'hidden')
  }

  const handleScroll=() => {
     const offset=window.scrollY;
     if(offset > 200 ){
       setScrolled(true);
       setLogoColorGreen(false);
     }
     else{
       setScrolled(false);
       setLogoColorGreen(true);
      
     }
   }

   const handleExpanded = () => {
     setExpanded(!expanded);
    if(expandedTwo) {
      setExpandedTwo(false)
    }

   }
   const handleExpandedTwo = () => {
    setExpandedTwo(!expandedTwo);
    if(expanded) {
      setExpanded(false)
    }
  }
  const handleExpandedMission = () => {
    setExpandedMission(!expandedMission);
    if(expandedMission) {
      setExpandedMission(false)
    }
  }
  

   let buttonClasses=['dropdown-menu'];

  if(expanded){
    buttonClasses.push('open');
   
  }

  if(expandedTwo){
    buttonClasses.push('openTwo');
  }
  if(expandedMission){
    buttonClasses.push('openMission');
   
  }
      useEffect(() => {
      window.addEventListener('scroll',handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [scrolled, logoColorWhite]);
 
   let navbarClasses=['navbar'];
   if(scrolled){
     navbarClasses.push('scrolled');
    
   }
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  

  return (
    <HeaderWrapper className={navbarClasses.join(" ")}>
      <HeaderContainer>
        <LogoContainer to="/" aria-label="Home">
           {logoColorWhite ? (
             <GatsbyImage
               image={logo.gatsbyImageData}
               alt={`MindfulCommerce home page`}
             />
 
           ) : (
             <GatsbyImage
               image={greenLogo.gatsbyImageData}
               alt={`MindfulCommerce home page`}
             />
           )}
        </LogoContainer>
      
          <Navigation>
            {/* <MenuItem to={navHeaderFirstLink} title={navHeaderFirstName} className="stripped" stripHash>{navHeaderFirstName}</MenuItem> */}
            <DropdownContainerMission>
              <DropdownButton aria-haspopup="menu" aria-expanded={expandedMission} onClick={handleExpandedMission} onMouseEnter={handleExpandedMission}>{navHeaderFirstDropdownTitle}</DropdownButton>  
              {logoColorWhite ? (
                <WhiteCaret /> 
              ) : (
                <GreenCaret />
              )}
      
            
              <DropdownContent className={buttonClasses.join(" ")} onMouseLeave={handleExpandedMission}>
              {navHeaderFirstDropdown.map((item) => (
              <SubMenuItem key={item.name} to={item.link} title={item.name} className="stripped"
              stripHash>
                {item.name}
              </SubMenuItem>
                          

            ))}
             
              </DropdownContent>
            </DropdownContainerMission>
            <DropdownContainer>
              <DropdownButton aria-haspopup="menu" aria-expanded={expanded} onClick={handleExpanded} onMouseEnter={handleExpanded}>{navHeaderSecondDropdownTitle}</DropdownButton>
              {logoColorWhite ? (
                <WhiteCaret /> 
              ) : (
                <GreenCaret />
              )}
              <DropdownContent className={buttonClasses.join(" ")} onMouseLeave={handleExpanded}>
              {navHeaderSecondDropdown.map((item) => (
              <SubMenuItem key={item.name} to={item.link} title={item.name} className="stripped"
              stripHash>
                {item.name}
              </SubMenuItem>
            ))}
             
              </DropdownContent>
            </DropdownContainer>
            <DropdownContainerTwo>
              <DropdownButton aria-haspopup="menu" aria-expanded={expandedTwo} onClick={handleExpandedTwo} onMouseEnter={handleExpandedTwo}>{navHeaderThirdDropdownTitle}</DropdownButton>
              {logoColorWhite ? (
                <WhiteCaret /> 
              ) : (
                <GreenCaret />
              )}
              <DropdownContent className={buttonClasses.join(" ")} onMouseLeave={handleExpandedTwo}>
              {navHeaderThirdDropdown.map((item) => (
              <SubMenuItem key={item.name} to={item.link} title={item.name} className="stripped"
              stripHash>
                {item.name}
              </SubMenuItem>
            ))}
             
              </DropdownContent>
            </DropdownContainerTwo>
     
           </Navigation>


     
          <ButtonContainerDesktop>
            {pathname !== '/join-the-community' &&  
              <CTAButton
               to={getListedLink}
            
                id="primaryCTADesktop"
              >
                {headerCTA}
              
              </CTAButton>}
          
              <SecondaryCTAButton
                href={headerCTASecondaryDestination}
                target="_blank"
                rel="nofollow"
                id="secondaryCTADesktop"
              >
                {headerCTASecondaryText}
              
              </SecondaryCTAButton>
        
          </ButtonContainerDesktop>
    
       

    
            <HamburgerMenuContainer>
              <OpenHamburgerButton
                $menuIsOpen={menuIsOpen}
                onClick={() => handleMenuAction()}
                id="openHamburger"
              />
              <CloseHamburgerButton
                $menuIsOpen={menuIsOpen}
                onClick={() => handleMenuAction()}
                id="closeHamburger"

              />
            </HamburgerMenuContainer>
            <Menu menuIsOpen={menuIsOpen} closeMenu={closeMenu} logoColorWhite={logoColorWhite} />
    
    
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header
