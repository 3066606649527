/**
 * Formats full URLs into more human-friendly outputs excluding HTTP protocol & query strings.
 * @param {String} url
 * @returns {String}
 * @example
 *   formatUrl("https://www.google.com/search?q=mindfulcommerce"); // -> 'www.google.com/search'
 */
export function formatUrl(url) {
  return url.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')
}

/**
 * Adds commas to a large number with many digits to improve readability.
 * @param {number | String} priceAsInt
 * @returns {String}
 * @example
 *   numberWithCommas(100000); // -> '100,000'
 */
export function numberWithCommas(priceAsInt) {
  return priceAsInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @example
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalise = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  )

/**
 * Returns true if a url is not relative.
 * @param {String} url
 * @returns {Boolean}
 * @example
 *   isAbsoluteUrl("https://mindfulcommerce.io/") // -> true
 *   isAbsoluteUrl("/directory")                  // -> false
 */
export const isAbsoluteUrl = (url) => /^https?:\/\//i.test(url)

/**
 * Returns the number of days between two date obects.
 * @param {Date} dateOne
 * @param {Date} dateTwo
 * @returns {number}
 */
export const calculateDaysBetween = (dateOne, dateTwo) =>
  Math.round(Math.abs((dateOne - dateTwo) / (24 * 60 * 60 * 1000)))

/**
 * Appends a script tag to the document, immediately causing it to load
 * @param {String} src
 */
export const loadScript = (src) => {
  const tag = document.createElement('script')
  tag.async = false
  tag.src = src
  document.body.appendChild(tag)
}
